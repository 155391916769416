import React, { useState, useEffect } from "react";

const Modal = (props) => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState(false);
  const [formUrl, setFormUrl] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  const getData = async () => {
    if (typeof window === "undefined") {
      return {}
    }
    try {
      setLoading(true);
      const d = new Date()
      const api = "//data.mothership.tools/mothershiptools-ukprod/mothership/"
      const query = [
        props.type,
        props.typeid,
        `${props.type}s_data.json`
      ]
      const url = `${api}${query.join('/')}?t=${d.getTime()}`
      const req = await fetch(url)
      const res = await req.json()
      setData(res)
      const _logo = res.artist_logo_newsletter ? res.artist_logo_newsletter : res.artist_logo_image
      setLogo(_logo)
      const _formUrl = res.newsletter_use_url ? res.newsletter_url : res.artist_form_url
      setFormUrl(_formUrl)
      setShouldShow(getCookie())
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }
  
  useEffect(() => {
    getData();
  }, []);

  const getCookie = () => {
    if (!Boolean(document.cookie)) {
      return true; //Show the modal
    }
    var cookies = document.cookie.split(';');
    var showModal = true;
    cookies.map(function(cookie){
      if(cookie.includes("sme_newsletter_prompt")) {
        showModal = false;
      }
    });
    return showModal;
  }

  const setCookie = () => {
    const cookieExpiry = data.cookie_expiry ? data.cookie_expiry :{units:"months",amount:"3"};
    const cookieDate = new Date();
    switch (cookieExpiry.units) {
      case 'days':
        const days = Number(cookieExpiry.amount);
        cookieDate.setDate( cookieDate.getDate() + days );
        break;
      case 'weeks':
        const weeks = Number(cookieExpiry.amount) * 7;
        cookieDate.setDate( cookieDate.getDate() + weeks );
        break;
      case 'months':
        const months = Number(cookieExpiry.amount);
        cookieDate.setMonth( cookieDate.getMonth() + months );
        break;
      case 'years':
        const years = Number(cookieExpiry.amount);
        cookieDate.setFullYear( cookieDate.getFullYear() + years );
        break;
      default:
        cookieDate.setDate( cookieDate.getMonth() + 3 );
        break;
    }
    document.cookie = 'sme_newsletter_prompt=hidden; expires='+cookieDate.toGMTString()+';';
  }

  const handleLinkClick = () => {
    setCookie()
    setShouldShow(false)
    return true
  }
  const handleButtonClick = () => {
    setCookie()
    setShouldShow(false)
  }

  return (
    shouldShow && data ?
      <div 
        className="modal" 
      >
        <div 
          className="modal-close" 
          onClick={handleButtonClick} 
        >
          <span>X</span>
        </div>
        <div className="modal-bg"></div>
        <div className="modal-inner">
          <div className="content-container">
            {
              data.newsletter_skip_teaser ?
                <iframe 
                  src={formUrl} 
                  frameborder="0" 
                  allowfullscreen
                  title="formEmbed"
                />
              : <>
                <div className="left">
                  <div className="image-mobile">
                    <img 
                      src={data.newsletter_image_top.url} 
                      alt={`Sign up to the ${data.name} newsletter`}
                    />
                  </div>
                  <div className="image-desktop">
                    <img 
                      src={data.newsletter_image_left.url.url} 
                      alt={`Sign up to the ${data.name} newsletter`}
                    />
                  </div>
                </div>
                <div className="right">
                  <h1 className="logo">
                    <img src={logo} alt={data.name} />
                  </h1>
                  <div className="text">
                    <p>${data.newsletter_text}</p>
                  </div>
                  <div className="cta">
                    <a 
                      href={formUrl} 
                      target={data.newsletter_cta_target ? '_blank' : ''}
                      rel={data.newsletter_cta_target ? 'noreferrer noopener' : ''}
                      onClick={handleLinkClick}
                    >
                      ${data.newsletter_cta_text}
                    </a>
                  </div>
                  <a 
                    className="newsletter-dismiss" 
                    href="#"
                    onClick={handleButtonClick}
                  >No thanks</a>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    : ''
  )

}

export default Modal