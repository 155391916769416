import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Background from '../components/background'
import Button from '../components/button'
import bg1 from '../images/bg1.jpg'
import Modal from '../components/modal'
import Footer from '../components/footer'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Background 
      image={bg1} 
      size="cover" 
      position="center center"
      repeat="no-repeat"
      height="100vh"
      location="top"
    />
    <div className="abs-center">
      <div className="container">
        <h1 className="title">Make your own</h1>
        <h2 className="title">weekend boarding pass</h2>
        <Button 
          link="/create/" 
          text="Get started ›"
          type="link"
        />
      </div>
    </div>
    <Footer fixed />
    <Modal
      type="artist" 
      typeid="721"
    />
  </Layout>
)

export default IndexPage
